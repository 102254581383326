<template>
  <div>
    <b-card no-body>
      <div class="p-2 d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-start">
          <div class="mr-2">
            <label for="example-datepicker">{{
              T("Web.Generic.PeriodFrom", "Period from")
            }}</label>
            <b-form-datepicker v-model="fromDate" placeholder="YYYY-MM-DD" />
          </div>
          <div>
            <label for="example-datepicker">{{
              T("Web.Generic.PeriodTo", "Period to")
            }}</label>
            <b-form-datepicker v-model="toDate" />
          </div>
        </div>
        <div>
          <b-button @click="exportData">{{T("Web.Generic.Export")}} <feather-icon icon="DownloadIcon"
          /></b-button>
        </div>
        <!--
        <div>
          <b-form-checkbox id="showYearBefore" v-model="showYearBefore">
            {{ $t("Generic.ShowYearBefore") }}
          </b-form-checkbox>
        </div>
          -->
      </div>
      <page-loader :loading="loading">
        <b-table-simple small responsive v-if="!loading">
          <b-thead>
            <b-th></b-th>
            <b-th>{{ T("Web.Generic.Total", "Total") }}</b-th>
            <b-th>{{ T("Web.Generic.Drivings.Drivings", "Drivings") }}</b-th>
            <b-th v-for="(monthYear, index) in monthsInPeriod" :key="index">{{
              monthYear.label
            }}</b-th>
          </b-thead>
          <b-tbody>
            <b-tr class="table-primary">
              <b-td class="light-gray-bg font-weight-bold"></b-td>
              <b-td><small>[{{ T('Web.Generic.Units.Tonnage')}}]</small></b-td>
              <b-td><small>[{{ T('Web.Generic.Units.Pcs')}}]</small></b-td>
              <b-td v-for="(monthYear, index) in monthsInPeriod" :key="index"
                ><small>[{{ T('Web.Generic.Units.Tonnage')}}]</small></b-td
              >
            </b-tr>
            <b-tr>
              <b-td class="light-gray-bg font-weight-bold">Total</b-td>
              <b-td class="table-secondary font-weight-bold">{{
                getTotalTonsCollected() | toCurrency
              }}</b-td>
              <b-td class="table-secondary font-weight-bold">{{
                getTotalCollections() | toCurrency
              }}</b-td>
              <b-td
                class="light-gray-bg font-weight-bold"
                v-for="(monthYear, index) in monthsInPeriod"
                :key="index"
              >
                {{
                  getMonthTotal(monthYear.year, monthYear.month) | toCurrency
                }}
              </b-td>
            </b-tr>

            <b-tr v-for="fraction in sortedFractions" :key="fraction.id">
              <b-td class="light-gray-bg">
                {{ fraction.name }}
              </b-td>
              <b-td class="table-secondary"
                >{{ getTotalTonsForFraction(fraction.id) | toCurrency }}
              </b-td>
              <b-td class="table-secondary">{{
                getTotalCollectionsForFraction(fraction.id) | toCurrency
              }}</b-td>
              <b-td v-for="(monthYear, index) in monthsInPeriod" :key="index">
                {{
                  getFractionTonsCollectedForMonth(
                    monthYear.year,
                    monthYear.month,
                    fraction.id
                  ) | toCurrency
                }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </page-loader>
    </b-card>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import {
  BTableSimple,
  BThead,
  BTh,
  BTbody,
  BTr,
  BTd,
  BCard,
  BFormSelect,
  BFormDatepicker,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import ReportsService from "@/services/economics/reports.service";
import moment from "moment";
import PageLoader from "@/app/components/layout/PageLoader.vue";
export default {
  components: {
    BFormDatepicker,
    BFormCheckbox,
    BFormSelect,
    BTableSimple,
    BThead,
    BTbody,
    BButton,
    BCard,
    BTh,
    BTd,
    BTr,
    PageLoader,
  },
  data() {
    return {
      loading: true,
      fromDate: "2022-01-01",
      toDate: "2022-12-31",
      showYearBefore: false,

      reportData: null,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      selectedStart: "2022",
    };
  },
  async created() {
    this.toDate = moment(new Date()).format("YYYY-MM-DD");
    this.fromDate = moment(new Date()).subtract("year", 1).format("YYYY-MM-DD");

    await this.fetchWasteFractions();
    await this.fetchReport();
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      wasteFractions: "wasteFractions/list",
      currentLocation: "appUser/getCurrentLocationScope",
    }),
    fractionOptions() {
      let result = this.wasteFractions.map((f) => {
        return {
          id: f.id,
          label: f.name,
        };
      });
      result.push({
        id: "00000000-0000-0000-0000-000000000000",
        name: "Uden fraktion",
      });
      return result;
    },
    monthsInPeriod() {
      let list = [];
      var fromDate = moment(this.fromDate);
      var toDate = moment(this.toDate);
      var currDate = fromDate;

      while (currDate < toDate) {
        list.push({
          label: currDate
            .toDate()
            .toLocaleString("en-us", { month: "short", year: "numeric" }),
          year: currDate.toDate().getFullYear(),
          month: currDate.toDate().getMonth() + 1,
        });
        currDate = currDate.add(1, "M");
      }
      return list;
    },

    sortedFractions() {
      let fractions = this.wasteFractions;
      fractions.push({
        id: "00000000-0000-0000-0000-000000000000",
        name: "Uden fraktion",
      });
      return fractions.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    yearOptions() {
      var currentYear = new Date().getFullYear(),
        years = [];
      let startYear = startYear || 2015;
      while (startYear <= currentYear) {
        let option = startYear++;
        years.push({ value: option, text: option });
      }
      return years.reverse();
    },
  },
  methods: {
    ...mapActions({ fetchWasteFractions: "wasteFractions/fetchList" }),
    async fetchReport() {
      const service = new ReportsService();
      this.reportData = (
        await service.getWasteAmountsReportForLocation(
          this.currentLocation ? this.currentLocation.id : null,
          this.fromDate,
          this.toDate
        )
      ).data.data;
    },

    getFractionTonsCollectedForMonth(year, month, fractionId) {
      if (this.reportData == null) return 0;
      var fractionData = this.reportData.find((e) => e.id == fractionId);
      if (!fractionData) return 0;
      var monthEntry = fractionData.entries.find(
        (e) => e.month == month && e.year == year
      );
      if (!monthEntry) return 0;
      return monthEntry.tonsWasteCollected;
    },

    getTotalTonsForFraction(fractionId) {
      if (this.reportData == null) return 0;
      var fractionData = this.reportData.find((e) => e.id == fractionId);
      if (!fractionData) return 0;
      return fractionData.entries.reduce(
        (prev, curr) => (prev += curr.tonsWasteCollected),
        0
      );
    },

    getTotalCollectionsForFraction(fractionId) {
      if (this.reportData == null) return 0;
      var fractionData = this.reportData.find((e) => e.id == fractionId);
      if (!fractionData) return 0;
      return fractionData.entries.reduce(
        (prev, curr) => (prev += curr.wasteCollections),
        0
      );
    },
    getMonthTotal(year, month) {
      let total = 0;
      if (this.reportData == null) return 0;
      this.reportData.forEach((fraction) => {
        let monthentry = fraction.entries.find(
          (e) => e.month == month && e.year == year
        );
        if (monthentry) {
          total += monthentry.tonsWasteCollected;
        }
      });
      return total;
    },
    getTotalCollections() {
      let total = 0;
      if (this.reportData == null) return 0;
      this.reportData.forEach((fraction) => {
        total += fraction.entries.reduce(
          (prev, curr) => (prev += curr.wasteCollections),
          0
        );
      });
      return total;
    },
    getTotalTonsCollected() {
      let total = 0;
      if (this.reportData == null) return 0;
      this.reportData.forEach((fraction) => {
        total += fraction.entries.reduce(
          (prev, curr) => (prev += curr.tonsWasteCollected),
          0
        );
      });
      return total;
    },
    exportData() {
      const fileName = `${this.fromDate}_${this.toDate}.xlsx`;
      const modifiedData = this.sortedFractions.map((item) => {
        let data = {
          "": item.name,
          Total: this.$options.filters.toCurrency(
            this.getTotalTonsForFraction(item.id)
          ),
          Drivings: this.$options.filters.toCurrency(
            this.getTotalCollectionsForFraction(item.id)
          ),
        };
        this.monthsInPeriod.forEach((month) => {
          data[month.label] = this.$options.filters.toCurrency(
            this.getFractionTonsCollectedForMonth(
              month.year,
              month.month,
              item.id
            )
          );
        });
        return data;
      });
      let totalData = {
        "": "Total",
        Total: this.$options.filters.toCurrency(this.getTotalTonsCollected()),
        Drivings: this.$options.filters.toCurrency(this.getTotalCollections()),
      };

      this.monthsInPeriod.forEach((month) => {
        totalData[month.label] = this.$options.filters.toCurrency(
          this.getMonthTotal(month.year, month.month)
        );
      });

      modifiedData.unshift(totalData);

      const worksheet = XLSX.utils.json_to_sheet(modifiedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, fileName);
    },
  },
  watch: {
    currentLocation(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.loading = true;
      this.fetchReport();
      this.loading = false;
    },
    fromDate(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.loading = true;
      this.fetchReport();
      this.loading = false;
    },
    toDate(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.loading = true;
      this.fetchReport();
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.total-tr {
  font-size: 15px;
  font-weight: bold;
}
.light-gray-bg {
  background-color: #f3f2f7;
}
.table-responsive {
  margin-bottom: 0;
}
</style>